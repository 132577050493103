import { defineStore } from 'pinia';

import { cacheStore, cacheFetch } from '../lib/cache-storage';

export const useDataStore = defineStore('filters', {
	state: () => ({
		loaded: false,
		measurementMethods: {},
		confirmationCompanies: [],
		clientAccounts: [],
		siteLocations: [],
		regions: [],
		areas: [],
	}),

	actions: {
		async init(force = false) {
			// console.log(`DATA INIT [${force ? 'force fetch' : 'allow cache'}]`)
			const { location } = window
			const urlCurrent = new URL(location)
			// hard coded fugitive_measurements for reliable way to get data
			const urlFetchData = new URL(`${urlCurrent.origin}/clientside/data`)
			const response = await fetch(urlFetchData)
			const data = await response.json()
			return this.setData(data)
		},
		setData(data) {
			this.measurementMethods = data.measurement_methods
			this.confirmationCompanies = data.confirmation_companies
			this.clientAccounts = data.client_accounts
			this.clientAccounts.sort((a, b) => a.client_name > b.client_name ? 1 : -1)
			this.siteLocations = data.site_locations
			this.siteLocations.sort((a, b) => a.code > b.code ? 1 : -1)
			this.regions = data.site_locations.reduce((items, item) => {
				const { region } = item
				if (!region) return items;
				return items.includes(region) ? items : [...items, region]
			}, [])
			this.regions.sort((a, b) => a > b ? 1 : -1)
			const areas = data.site_locations.reduce((items, item) => {
				const { area_slugify, area, region } = item
				if (!area_slugify) return items;
				return items && items[area_slugify] ? items : { ...items, [area_slugify]: { area_slugify, area, region }}
			}, {})
			this.areas = Object.values(areas)
			this.areas.sort((a, b) => a.title > b.title ? 1 : (b.title > a.title) ? -1 : 0)
			this.loaded = true
		},
	},

	getters: {
	},

});