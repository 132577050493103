export default {
  "inspection_date": "Inspection Date",
  "site_location": "Site Location",
  "compressor_type": "Compressor Type",
  "compressor_unit_number": "Unit #",
  "measurement_date_and_method": "Measurement Date and Method",
  "total_emissions_rate_by_type_std": "Total Emissions Rate (STP)",
  "number_of_throws": "Number of Throws",
  "average_rate_per_throw_std": "Average Rate per Throw",
  "controlled": "Source Controlled?",
  "seal_meterable": "Seal Meterable?",
  "afr_panel_make_id": "Afr Panel Make ID",
  "afr_panel_model": "Afr Panel Model",
  "afr_panel_model_id": "Afr Panel Model ID",
  "air_fuel_ratio_panel_photo": "Air Fuel Ratio Panel Photo",
  "air_fuel_ratio_panel_photo_filename": "Air Fuel Ratio Panel Photo Filename",
  "air_fuel_ratio_panel_present": "Air Fuel Ratio Panel Present?",
  "area": "Area",
  "bridger_emission_id": "Bridger Emission ID",
  "client_account": "Client Account",
  "compressor_engine_make_id": "Compressor Engine Make ID",
  "compressor_engine_model_id": "Compressor Engine Model ID",
  "compressor_image": "Compressor Photo",
  "compressor_make_id": "Compressor Make ID",
  "compressor_model": "Compressor Model",
  "compressor_model_id": "Compressor Model ID",
  "compressor_rated_75kw": "Compressor Rating >= 75kW",
  "compressor_vents_clearly_labeled": "Compressor Vents Clearly Labeled?",
  "created_at": "Created At",
  "data_source": "Data Source",
  "decommissioned": "Decommissioned?",
  "device_coordinates": "Device Coordinates",
  "device_latitude": "Device Latitude",
  "device_longitude": "Device Longitude",
  "discarded_at": "Discarded At",
  "effective_reporting_date": "Effective Reporting Date",
  "emission_control_method": "Emission Control Method",
  "engine_driver_type": "Engine Driver Type",
  "engine_kw_rating": "Engine Rating",
  "engine_kw_rating_unobtainable": "Engine Rating Unobtainable?",
  "engine_nameplate_photo": "Engine Nameplate Photo",
  "engine_nameplate_photo_filename": "Engine Nameplate Photo Filename",
  "engine_nameplate_photo_obtainable": "Engine Nameplate Photo Unobtainable?",
  "engine_panel_type": "Engine Panel Type",
  "engine_serial": "Engine Serial Number",
  "engine_starter_ventto_atmosphere": "Engine Starter Vent to Atmosphere?",
  "exhaust_sample_port_filename": "Exhaust Sample Port Filename",
  "exhaust_sample_port_photo": "Exhaust Sample Port Photo",
  "exhaust_sample_port_present": "Exhaust Sample Port Present?",
  "facility": "Facility",
  "facility_id": "Facility ID",
  "facility_id_code_unobtainable": "Facility Code Unobtainable?",
  "filename_compressor_image": "Filename Compressor Image",
  "id": "ID",
  "inside_left_exhaust_photo": "Inside Left Exhaust Photo",
  "inside_left_exhaust_photo_filename": "Inside Left Exhaust Photo Filename",
  "inside_right_exhaust_photo": "Inside Right Exhaust Photo",
  "inside_right_exhaust_photo_filename": "Inside Right Exhaust Photo Filename",
  "installed_on_or_after_01jan22": "Installed On/After Jan 01 2022?",
  "non_selective_catalyst_photo": "Non-Selective Catalyst Photo",
  "non_selective_catalyst_photo_filename": "Non Selective Catalyst Photo Filename",
  "non_selective_catalyst_present": "Non-Selective Catalyst Present?",
  "notes": "Notes",
  "nscr_before_or_after_exhaust_sample_port": "NSCR - Before or After Exhaust Sample Port",
  "sourceis_operating": "Operating?",
  "outside_stack_exhaust_photo": "Outside Stack Exhaust Photo",
  "outside_stack_exhaust_photo_filename": "Outside Stack Exhaust Photo Filename",
  "process_block_id": "Process Block ID",
  "process_block_id_unobtainable": "Process Block ID Unobtainable?",
  "region": "Region",
  "release_point_coordinates": "Release Point Coordinates",
  "release_point_latitude": "Release Point Latitude",
  "release_point_longitude": "Release Point Longitude",
  "seal_inaccessibility_filename": "Seal Inaccessibility Filename",
  "seal_inaccessibility_notes": "Seal Inaccessibility Notes",
  "seal_inaccessibility_photo": "Seal Inaccessibility Photo",
  "seal_inaccessibility_reason": "Seal Inaccessibility Reason",
  "seal_type": "Seal Type",
  "compressor_serial_number": "Serial Number",
  "compressor_serial_number_unobtainable": "Serial Number Unobtainable?",
  "service_type": "Service Type",
  "service_type_composition": "Service Type Composition",
  "site_inspection_record": "Site Inspection Record",
  "site_inspection_record_id": "Site Inspection Record ID",
  "source_photo_count": "Source Photo Count",
  "stale": "Stale?",
  "compressor_start_gas_disposition": "Start Gas Disposition",
  "unique_post_id": "Unique Post ID",
  "unique_well_identifier_id": "Unique Well Identifier ID",
  "compressor_unit_number_unobtainable": "Unit Number Unobtainable?",
  "updated_at": "Updated At",
  "uwi_unobtainable": "UWI Unobtainable?"
}