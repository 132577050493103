<template>
<!--
  <field-select
    v-if="mode !== 'search'"
    type="fugitive-status"
    v-model="input"
    :options="options"
    :clearable="mode === 'search'"
    style="min-width: 100px;"
    :error="error"
  >
    <template #prepend>
      <q-icon :name="inputIcon" color="positive" />
    </template>
  </field-select>
  <q-list v-else style="min-width: 100px">
    <q-item
      v-for="{ value } in options"
      :key="`field-fugitive-status-${value}`"
      clickable
      @click="input = value"
    >
      <q-item-section>
        <display-fugitive-status :value="value" />
      </q-item-section>
    </q-item>
  </q-list>
-->
  <q-option-group
    v-model="input"
    :options="options"
    color="green"
    type="checkbox"
  >
    <template v-slot:label="opt">
      <display-fugitive-status :value="opt.value" />
    </template>
  </q-option-group>
</template>

<script setup>

import { ref, computed, watch, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import fieldSelect from './select.vue'

const { t } = useI18n()

const props = defineProps({
  modelValue: { type: Array, default: () => [] },
  title: String,
  mode: { type: String, default: 'input' },
  optional: Boolean,
  error: { type: String },
})

const options = [
  { value: 'outstanding' },
  { value: 'delayed' },
  { value: 'repaired' },
  { value: 'confirmed' },
	{ value: 'historical' },
]

const emit = defineEmits(['update:modelValue'])
const input = computed({
  get() {
    return props.modelValue || []
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const inputIcon = computed(() => props.mode === 'search' ? 'mdi-clipboard-search' : 'mdi-clipboard')

</script>
