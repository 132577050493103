export default {
  "activity_type": "Activity Type",
  "bc_colocated_facility": "BC Colocated Facility",
  "bc_colocated_facilityw_highest_frequency": "BC Colocated Facility w/ Highest Frequency",
  "code": "Code",
  "created_at": "Created At",
  "facility_representative": "Facility Representative",
  "facility_type": "Facility Type",
  "id": "ID",
  "name": "Name",
  "provincial_compliance_code": "Provincial Compliance Code",
  "site_location": "Site Location",
  "updated_at": "Updated At"
}