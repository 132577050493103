export default {
  "client_account": "Client Account",
  "code": "Location Code",
  "name": "Name",
  "country": "Country",
  "region": "Region",
  "area": "Area",
  "business_unit": "Business Unit",
  "recent_inspection_dates": "Recent Inspection Dates",
  "missing_facilities?": "Missing Facilities?",
  "complete_facility_list": "Complete Facility List",
  "missing_from_local": "Missing From Local Data",
  "missing_from_public": "Missing From Public Data",
  "area_slugify": "Area Slugify",
  "bc_permit_holder_contact_email": "BC Permit Holder Email",
  "bc_permit_holder_contact_name": "BC Permit Holder Name",
  "bc_permit_holder_contact_phone": "BC Permit Holder Phone Number",
  "client_account_id": "Client Account ID",
  "count_controlled_tanks": "Controlled Tank Count",
  "count_dehydrators": "Dehydrator Count",
  "count_recip_cent_compressors": "Reciprocating Compressor Count",
  "count_screw_compressors": "Screw Compressor Count",
  "count_separators": "Separator Count",
  "count_uncontrolled_tanks": "Uncontrolled Tank Count",
  "count_wells": "Well Count",
  "created_at": "Created At",
  "data_source": "Data Source",
  "discarded_at": "Discarded At",
  "has_instrument_air": "Has Instrument Air?",
  "id": "ID",
  "updated_at": "Updated At"
}