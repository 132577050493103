<template>
  <field-text
    v-model="input"
    type="password"
    :hint="hint"
    :error="error"
    :rules-append="[ruleAppend]"
  />
</template>

<script setup>

import { ref, computed, watch, inject } from 'vue'
import fieldText from './text.vue'

const props = defineProps({
  modelValue: { type: String, required: false },
  mode: { type: String, default: 'input' },
  hint: { type: String, default: '' },
  optional: Boolean,
  error: { type: String },
})

// placeholder for better rules, going to rely on server more for now
const ruleAppend = (value) => value && value.length > 6 || 'Please make a longer password'

const emit = defineEmits(['update:modelValue'])
const input = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

</script>
