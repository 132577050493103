<template>
  <q-field
    :label="label"
    ref="field"
    v-model="input"
    stack-label
    borderless
    :rules="rules"
		:clearable="optional"
    class="q-mb-md q-mt-md"
    :error="!!error"
    :errorMessage="error"
  >
    <template v-slot:control>
      <q-date
        v-model="input"
        :options="options"
        bordered
        landscape
        color="secondary"
        mask="YYYY-MM-DD"
        class="shadow-0 q-mb-none"
      />
    </template>
  </q-field>
</template>

<script setup>

import { computed } from 'vue'
import buildRules from '../../lib/build-rules'

const props = defineProps({
  label: String,
  modelValue: { type: String, required: false },
  optional: { type: Boolean, default: false },
  minDate: { type: String },
  maxDate: { type: String },
  rulesAppend: { type: Array, default: () => [] },
  options: { type: [Array, Function], default: undefined },
  error: { type: String },
})

const valueRules = [
  (value) => isValidDate(value) || 'Date is not valid',
  (value) => props.minDate && value ? (value >= props.minDate || `Date must be later`) : true,
  (value) => props.maxDate && value ? (value <= props.maxDate || `Date must be earlier`) : true,
  ...(props.rulesAppend || []),
]
const rules = computed(() => buildRules(valueRules, props.optional))

function isValidDate(dateString) {
  if (!dateString || typeof dateString !== 'string') return true
  if (!dateString.match(/^\d{4}-\d{2}-\d{2}$/)) return false
  var d = new Date(dateString)
  var dNum = d.getTime()
  if (!dNum && dNum !== 0) return false
  return d.toISOString().slice(0, 10) === dateString
}

const emit = defineEmits(['update:modelValue'])
const input = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

</script>
