export default function buildRules(rules, optional) {
  if (optional) {
    return [
      ((value) => {
        if (value === '' || value === null) return true
        const validateValue = rules.map((fn) => fn(value)).find((value) => value !== true)
        return validateValue ? validateValue : true
       return true
      }),
    ]
  }
  return [
    (value) => typeof value === 'undefined' || value === '' || value === null ? 'Value is required' : true,
    ...rules,
  ]
}