export default {
  "inspection_date": "Inspection Date",
  "site_location": "Site Location",
  "unique_tank_identifier": "Unique Tank Identifier",
  "measurement_method": "Measurement Method",
  "measurement_device": "Measurement Device",
  "emissions_rate_std": "Emissions Rate (STP)",
  "area": "Area",
  "storage_contents": "Contents",
  "created_at": "Created At",
  "data_source": "Data Source",
  "discarded_at": "Discarded At",
  "emission_video_link": "Emission Video",
  "emissions_rate": "Emissions Rate",
  "emissions_rate_not_measurable": "Emissions Rate Not Measurable?",
  "facility": "Facility",
  "gpe_media": "GPE Media Number",
  "id": "ID",
  "measurement_device_id": "Measurement Device ID",
  "measurement_method_id": "Measurement Method ID",
  "region": "Region",
  "roof_type": "Roof Type",
  "site_inspection_record": "Site Inspection Record",
  "site_inspection_record_id": "Site Inspection Record ID",
  "controlled": "Source Controlled?",
  "stale": "Stale?",
  "url_tank_photo": "Tank Photo",
  "tank_size": "Tank Size",
  "tank_source": "Tank Source",
  "tank_source_id": "Tank Source",
  "unique_post_id": "Unique Post ID",
  "updated_at": "Updated At"
}