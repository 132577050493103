<template>
	<span v-if="value !== '' && value !== null" class="text-no-wrap"> 
		{{ display }}
		<q-tooltip :delay="400" :offset="[0, 2]" class="text-body2">
			<div class="row items-center">
				<q-icon name="mdi-information-outline" class="q-pr-xs" size="18px" />
				<span><display-number :value="numDays" /> {{ t('types.date.days-ago') }}</span>
			</div>
		</q-tooltip>
	</span>
</template>

<script setup>

import { computed } from 'vue'
import { date } from 'quasar'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps(['value'])

const display = computed(() => props.value ? date.formatDate(date.addToDate(`${props.value} 00:00:00`, { hours: 20 }), 'MMM DD/YY') : '')
const numDays = computed(() => date.getDateDiff(new Date(), props.value, 'days'))

</script>
