<template>
	<span v-if="value !== '' && value !== null" class="text-no-wrap">
		<q-icon
			:name="icon"
			:color="color"
			class="q-mr-xs"
		/>{{ t(`types.boolean.${label}`) }}
	</span>
</template>

<script setup>

import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps(['value'])

const isIndeterminate = computed(() => props.value === '' || props.value === null || props.value === 'null')
const isTrue = computed(() => props.value && props.value !== 'false')

const icon = computed(() => {
	if (isIndeterminate.value) return 'mdi-minus-box'
	return isTrue.value ? 'mdi-check-bold' : 'mdi-close-thick'
})

const color = computed(() => {
	if (isIndeterminate.value)  return 'grey-5'
	return isTrue.value ? 'green-9' : 'red-10'
})

const label = computed(() => {
	if (isIndeterminate.value)  return 'indeterminate'
	return isTrue.value ? 'positive' : 'negative'
})

</script>
