<template>
  <field-text
    v-model="input"
    type="email"
    :hint="hint"
    :error="error"
    :rules-append="[ruleAppend]"
  >
    <template #prepend>
      <q-icon name="mdi-email" color="blue-grey-7" />
    </template>
  </field-text>
</template>

<script setup>

import { ref, computed, watch, inject } from 'vue'
import fieldText from './text.vue'
import { testPattern } from 'quasar/src/utils/patterns'

const props = defineProps({
  modelValue: { type: String, required: false },
  mode: { type: String, default: 'input' },
  hint: { type: String, default: '' },
  optional: Boolean,
  error: { type: String },
})

const ruleAppend = (value) => testPattern.email(value) || 'Invalid Email Adddress'

const emit = defineEmits(['update:modelValue'])
const input = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

</script>
