<template>
  <q-field
    v-if="mode !== 'search'"
    :label="label"
    ref="field"
    v-model="input"
    stack-label
    borderless
    :rules="rules"
		:clearable="optional"
    class="q-mb-md q-mt-md"
    :error="!!error"
    :errorMessage="error"
  >
    <template v-slot:control>
      <q-toggle
        v-model="input"
      />
    </template>
  </q-field>
  <q-list v-else>
    <q-item clickable @click="input = true">
      <q-item-section>
        <display-boolean :value="true" />
      </q-item-section>
    </q-item>
    <q-item clickable @click="input = false">
      <q-item-section>
        <display-boolean :value="false" />
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script setup>

import { computed } from 'vue'
import buildRules from '../../lib/build-rules'

const props = defineProps({
  label: String,
  mode: { type: String, default: 'input' },
  modelValue: { type: Boolean, required: false },
  optional: { type: Boolean, default: false },
  error: { type: String },
})

const valueRules = [
  (value) => typeof value === 'boolean' || 'Value must be true or false',
]
const rules = computed(() => buildRules(valueRules, props.optional))

const emit = defineEmits(['update:modelValue'])
const input = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

</script>
