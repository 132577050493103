<template>
	<q-btn
		v-if="value !== '' && value !== null"
		flat
		:label="value"
		dense
		no-caps
		class="text-body2 text-no-wrap"
	>
		<q-menu auto-close="">
			<q-list>
				<q-item
					clickable
					@click="copy()"
				>
					<q-item-section side>
						<q-icon name="mdi-content-copy" size="14px" color="grey-7" />
					</q-item-section>
					<q-item-section no-wrap>Copy Phone Number</q-item-section>
				</q-item>
				<q-item
					clickable
					:href="`tel:${value}`"
				>
					<q-item-section side>
						<q-icon name="mdi-phone-dial" size="14px" color="grey-7" />
					</q-item-section>
					<q-item-section no-wrap>Call Number</q-item-section>
				</q-item>
			</q-list>
		</q-menu>
	</q-btn>
</template>

<script setup>

import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps(['value'])

function copy() {
	navigator.clipboard.writeText(props.value)
}

</script>
