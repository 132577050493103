import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { useLayoutStore } from '../stores/layout'
import { useUserStore } from '../stores/user'

const router = createRouter({
  history: createWebHistory('/'),
  routes,
})

router.beforeEach(async (to, from) => {
  if (typeof to.meta.hideWrapper === 'boolean') {
		const layoutStore = useLayoutStore()
    layoutStore.setHideWrapper(to.meta.hideWrapper)
  }
  const userStore = useUserStore()
  if (!userStore.loaded) await userStore.check()
  if (to.meta.requiresAuth && !userStore.authorized) return { name: 'login' }
  if (userStore.authorized && to.path === '/users/sign_in') return { path: '/' }
})

export default router