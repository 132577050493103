<template>
	<span v-if="value !== '' && value !== null">
		{{ method.name }}
	</span>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDataStore } from '../../stores/data'

const { t } = useI18n()
const dataStore = useDataStore()

const props = defineProps(['value'])

const method = computed(() => {
	return dataStore.measurementMethods.find(({ id }) => id === props.value)
})

</script>
