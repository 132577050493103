import { defineStore } from 'pinia';

export const useLayoutStore = defineStore('layout', {
	state: () => ({
		drawerOpen: true,
		hideWrapper: false,
		pageTitle: 'GreenPath',
		typeView: false,
		pageButtons: [],
		sidebarWidth: 220,
		navigation: [
			{
				index: 'home',
				path: '/',
			},
			{
				index: 'fugitive_measurements',
				path: '/inspection/fugitive_measurements',
			},
			{
				index: 'compressor',
				children: [
					{
						index: 'compressor_sources',
						path: '/inspection/compressor_sources',
					},
					{
						index: 'compressor_seal_measurements',
						path: '/inspection/compressor_seal_measurements',
					},
				],
			},
			{
				index: 'pneumatic',
				children: [
					{
						index: 'pneumatic_sources',
						path: '/inspection/pneumatic_sources',
					},
					{
						index: 'pneumatic_measurements',
						path: '/inspection/pneumatic_measurements',
					},
				]
			},
			{
				index: 'tank',
				children: [
					{
						index: 'tank_sources',
						path: '/inspection/tank_sources',
					},
					{
						index: 'tank_measurements',
						path: '/inspection/tank_measurements',
					},
				]
			},
			{
				index: 'fired_equipment_sources',
				path: '/inspection/fired_equipment_sources',
			},
			{
				index: 'dehydrator_sources',
				path: '/inspection/dehydrator_sources',
			},
			{
				index: 'site_inspection_records',
				path: '/inspection/site_inspection_records',
			},
			{
				index: 'site_locations',
				path: '/inspection/site_locations',
			},
		],
	}),

	actions: {
		setDrawerOpen(value) {
			this.drawerOpen = !!value
		},
		setHideWrapper(value) {
			this.hideWrapper = !!value
		},
		setPageTitle(value) {
			this.pageTitle = value
		},
		setPageInfo(title, type, buttons) {
			this.pageTitle = title
			this.typeView = type
			this.pageButtons = buttons || []
		},
		setPageButtons(buttons) {
			this.pageButtons = buttons || []
		},
	},

	getters: {
	},

});