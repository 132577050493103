const routes = [
  {
    path: '/',
    component: () => import('../layout/layout.vue'),
    children: [
      {
        path: '',
        component: () => import('../pages/index.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'inspection',
        component: () => import('../pages/inspection.vue'),
        meta: { requiresAuth: true, hideWrapper: false },
        children: [
          {
            path: 'fugitive_measurements/:id',
            component: () => import('../pages/inspection/details/fugitive_measurements.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'fugitive_measurements',
            component: () => import('../pages/inspection/fugitive_measurements.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'compressor_sources/:id',
            component: () => import('../pages/inspection/details/compressor_sources.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'compressor_sources',
            component: () => import('../pages/inspection/compressor_sources.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'pneumatic_sources/:id',
            component: () => import('../pages/inspection/details/pneumatic_sources.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'pneumatic_sources',
            component: () => import('../pages/inspection/pneumatic_sources.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'tank_sources/:id',
            component: () => import('../pages/inspection/details/tank_sources.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'tank_sources',
            component: () => import('../pages/inspection/tank_sources.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'fired_equipment_sources/:id',
            component: () => import('../pages/inspection/details/fired_equipment_sources.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'fired_equipment_sources',
            component: () => import('../pages/inspection/fired_equipment_sources.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'dehydrator_sources/:id',
            component: () => import('../pages/inspection/details/dehydrator_sources.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'dehydrator_sources',
            component: () => import('../pages/inspection/dehydrator_sources.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'site_inspection_records/:id',
            component: () => import('../pages/inspection/details/site_inspection_records.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'site_inspection_records',
            component: () => import('../pages/inspection/site_inspection_records.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'site_locations/:id',
            component: () => import('../pages/inspection/details/site_locations.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'site_locations',
            component: () => import('../pages/inspection/site_locations.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'compressor_seal_measurements/:id',
            component: () => import('../pages/inspection/details/compressor_seal_measurements.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'compressor_seal_measurements',
            component: () => import('../pages/inspection/compressor_seal_measurements.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'pneumatic_measurements/:id',
            component: () => import('../pages/inspection/details/pneumatic_measurements.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'pneumatic_measurements',
            component: () => import('../pages/inspection/pneumatic_measurements.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'tank_measurements/:id',
            component: () => import('../pages/inspection/details/tank_measurements.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'tank_measurements',
            component: () => import('../pages/inspection/tank_measurements.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'facilities/:id',
            component: () => import('../pages/inspection/details/facilities.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'unique_well_identifiers/:id',
            component: () => import('../pages/inspection/details/unique_well_identifiers.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'fugitive_sources/:id',
            component: () => import('../pages/inspection/details/fugitive_sources.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: 'users',
        component: () => import('../pages/users.vue'),
        meta: { hideWrapper: true, requiresAuth: false },
        children: [
          {
            name: 'login',
            path: 'sign_in',
            component: () => import('../pages/users/sign_in.vue'),
            meta: { requiresAuth: false },
          },
          {
            name: 'reset_password',
            path: 'password/new',
            component: () => import('../pages/users/reset_password.vue'),
            meta: { requiresAuth: false },
          },
          {
            name: 'set_password',
            path: 'password/edit',
            component: () => import('../pages/users/set_password.vue'),
            meta: { requiresAuth: false },
          },
          {
            name: 'profile',
            path: 'profile',
            component: () => import('../pages/users/profile.vue'),
            meta: { hideWrapper: false },
          },
        ],
      },
      {
        path: 'data',
        component: () => import('../pages/data.vue'),
        meta: { hideWrapper: false },
      },
      {
        path: 'display',
        component: () => import('../pages/display.vue'),
        meta: { hideWrapper: false },
      },
      {
        path: 'form',
        component: () => import('../pages/form.vue'),
        meta: { hideWrapper: false },
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('../pages/404.vue'),
  },
];

export default routes;
