<template>
	<q-select
		v-model="input"
		:options="options"
		dense
    :outlined="!borderless"
    :borderless="borderless"
		:label="label"
		class="q-mb-sm"
		:rules="rules"
		:clearable="optional"
    :error="!!error"
    :errorMessage="error"
	>
		<!-- display selected -->
		<template v-slot:selected>
			<component
				v-if="input"
				:is="`display-${type}`"
				:value="input"
			/>
		</template>

		<!-- display option -->
		<template v-slot:option="scope">
			<q-item v-bind="scope.itemProps">
				<q-item-section>
					<component
						:is="`display-${type}`"
						:value="scope.opt.value"
					/>
				</q-item-section>
			</q-item>
		</template>

		<template #prepend>
			<slot name="prepend" />
		</template>
		<template #append>
			<slot name="append" />
		</template>

	</q-select>
</template>

<script setup>

import { ref, computed, watch, inject } from 'vue'
import buildRules from '../../lib/build-rules'

const props = defineProps({
	// TODO: this needs to be a simple array of values, not { value }
	options: { type: Array, required: true },
  type: { type: String, default: 'text' },
  modelValue: { type: String, required: false },
  label: String,
  borderless: Boolean,
  optional: Boolean,
  rulesAppend: { type: Array, default: () => [] },
  error: { type: String },
})

const valueRules = [
	(value) => isValidOption(value) || 'Must select a value',
  ...(props.rulesAppend || []),
]

const rules = computed(() => buildRules(valueRules, props.optional))

function isValidOption(value) {
	return !!props.options.find((option) => option === value || value === option.value)
}


const emit = defineEmits(['update:modelValue'])
const input = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', (value && typeof value.value !== 'undefined') ? value.value : value)
  },
})

</script>
