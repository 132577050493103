<template>
	<span v-if="value !== '' && value !== null" class="text-no-wrap">
		<display-number :value="value" :sigfigs="typeof sigfigs === 'number' ? sigfigs : 4" />
		<span class="display-unit" :style="dark ? 'color: inherit;' : ''">{{ std ? 'std ' : '' }}m3/h{{ hasThrow ? 'throw ' : '' }}</span>
	</span>
</template>

<script setup>

import { computed } from 'vue'

const props = defineProps(['value', 'sigfigs', 'std', 'throw', 'dark'])

const hasThrow = computed(() => !!props.throw)

</script>
