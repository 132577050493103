import { Notify, Cookies } from 'quasar'
import { defineStore } from 'pinia'
import router from '../router'

import { useDataStore } from '../stores/data'

export const useUserStore = defineStore('user', {
	state: () => ({
		loaded: false,
		authorized: false,
		user: null,
		csrfToken: null,
	}),

	actions: {
		async check() {
			try {
				const response = await fetch('/clientside')
				const data = await response.json()
				this.authorized = (data && data.authorized)
				this.user = data && data.user ? data.user : null
				this.csrfToken = data.csrf || null
			} catch (err) {
				this.authorized = false
				const { path } = router.currentRoute.value
				console.log('---------------')
				console.log('check path', path)
				console.log(' href => ' + window.location.href);
				console.log(' pathname => ' + window.location.pathname);
				console.log(' hashpathname => ' + window.location.hash);
				console.log(' search=> ' + window.location.search);
				if ((window.location.pathname === '/' || window.location.pathname === '/users/sign_in') && !window.location.search.includes('refresh=1')) {
					console.log('REFRESH')
					// Cookies.remove('_portal_session')
					// window.location = '/?refresh=1'
				}
			}
			this.loaded = true
			return this.authorized
		},
		async login(email, password, remember) {
			const body = `commit=${encodeURIComponent('Log in')}&` + 
				`authenticity_token=${encodeURIComponent(this.getCsrfToken)}&` + 
				`${encodeURIComponent('user[email]')}=${encodeURIComponent(email)}&` + 
				`${encodeURIComponent('user[password]')}=${encodeURIComponent(password)}&` + 
				`${encodeURIComponent('user[remember_me]')}=${encodeURIComponent(remember ? 1 : 0)}&`

			const response = await fetch('/users/sign_in.json', {
				method: 'POST',
				mode: 'cors', // no-cors, *cors, same-origin
				cache: 'no-cache',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'X-CSRF-Token': this.getCsrfToken,
				},
				redirect: 'follow', // required for data return
				referrerPolicy: 'no-referrer',
				body,
			});

			const data = await response.json()
			if (data && data.error) {
				Notify.create({
					type: 'warning',
					message: data.error,
				})
			}
			await this.check()
			return this.authorized
		},
		async resetPassword(email) {
			const body = `commit=${encodeURIComponent('Send Me a Reset Link')}&` + 
				`authenticity_token=${encodeURIComponent(this.getCsrfToken)}&` + 
				`${encodeURIComponent('user[email]')}=${encodeURIComponent(email)}`

			const response = await fetch('/users/password', {
				method: 'POST',
				mode: 'cors', // no-cors, *cors, same-origin
				cache: 'no-cache',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'X-CSRF-Token': this.getCsrfToken,
				},
				redirect: 'follow', // required for data return
				referrerPolicy: 'no-referrer',
				body,
			});
			Notify.create({
				type: 'positive',
				icon: 'mdi-email-fast',
				message: 'You will receive an email with instructions on how to reset your password in a few minutes.',
				timeout: 30000,
				color: 'light-green-7',
				actions: [
					{ label: 'Dismiss', color: 'white', handler: () => {} }
				]
			})
			router.push('/users/sign_in')
		},
		// _method=put&authenticity_token=_yiMVXl1bvHGB50YVjCddyOzzWXJMSD9GG-PNaxljLgVv74r8I0CJHIBR09DEyj4q2gyAAvDltok2-NqaRwUnQ&user[reset_password_token]=WrfEdCzjDzovLdGaJB-U&user[password]=sSF6isBsVDZM2gt&user[password_confirmation]=sSF6isBsVDZM2gt&commit=Change+my+Password
		async setPassword(token, password, passwordConfirmation) {
			const body = `commit=${encodeURIComponent('Change my Password')}&` + 
				`_method=put&` + 
				`authenticity_token=${encodeURIComponent(this.getCsrfToken)}&` + 
				`${encodeURIComponent('user[reset_password_token]')}=${encodeURIComponent(token)}&` + 
				`${encodeURIComponent('user[password]')}=${encodeURIComponent(password)}&` + 
				`${encodeURIComponent('user[password_confirmation]')}=${encodeURIComponent(passwordConfirmation)}&`

			const response = await fetch('/users/password.json', {
				method: 'POST',
				mode: 'cors', // no-cors, *cors, same-origin
				cache: 'no-cache',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'X-CSRF-Token': this.getCsrfToken,
				},
				redirect: 'follow', // required for data return
				referrerPolicy: 'no-referrer',
				body,
			});

			const data = await response.json()
			if (data && data.error) {
				Notify.create({
					type: 'warning',
					message: data.error,
				})
			}
			Notify.create({
				type: 'positive',
				message: 'Password Successfully Saved',
			})
			router.push('/users/sign_in')
		},
		async logout() {
			const body = `_method=delete&authenticity_token=${encodeURIComponent(this.getCsrfToken)}`
			const response = await fetch('/users/sign_out', {
				method: 'POST',
				mode: 'cors',
				cache: 'no-cache',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'X-CSRF-Token': this.getCsrfToken,
				},
				// TODO: add referrer and "referrerPolicy": "strict-origin-when-cross-origin", ?
				referrerPolicy: 'no-referrer',
				body,
			});
			this.user = null
			this.authorize = false
			Cookies.remove('_portal_session')
			window.location = '/'
		},
		async setUserProfile({ full_name, email }) {
			this.user.full_name = full_name
			this.user.email = email
		}
	},

	getters: {
		getCsrfToken() {
			if (this.csrfToken) return this.csrfToken
			// fallback on meta tag
			return document.head.querySelector("meta[name=csrf-token]").content
		}
	},

});