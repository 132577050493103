export default {
  "inspection_date": "Inspection Date",
  "site_location": "Site Location",
  "region": "Region",
  "area": "Area",
  "pneumatic_component_type": "Component Type",
  "component_model": "Make & Model",
  "component_serial": "Serial Number",
  "pneumatic_driver_type": "Pneumatic Driver Type",
  "controlled": "Source Controlled?",
  "low_emission": "Low Emission?",
  "actuation_frequency": "Actuation Frequency",
  "bourdon_pressure_rating": "Bourdon Pressure Rating",
  "bourdon_pressure_rating_unobtainable": "Bourdon Pressure Rating Unobtainable?",
  "bridger_emission_id": "Bridger Emission ID",
  "chemical_injection_pressure": "Chemical Injection Pressure",
  "chemical_type_injected": "Chemical Injection Type",
  "client_account": "Client Account",
  "component_action": "Component Action",
  "component_condition": "Component Condition",
  "component_image": "Component Photo",
  "component_image_filename": "Component Image Filename",
  "component_interface": "Component Interface",
  "component_model_id": "Component Model ID",
  "created_at": "Created At",
  "data_source": "Data Source",
  "decommissioned": "Decommissioned?",
  "device_coordinates": "Device Coordinates",
  "device_latitude": "Device Latitude",
  "device_longitude": "Device Longitude",
  "discarded_at": "Discarded At",
  "effective_reporting_date": "Effective Reporting Date",
  "pump_electricity_source": "Electricity Source",
  "emergency_shutdown_device": "Emergency Shutdown Device?",
  "emission_control_method": "Emission Control Method",
  "facility": "Facility",
  "facility_id": "Facility ID",
  "facility_id_code_unobtainable": "Facility Code Unobtainable?",
  "fuel_gas_offtake_point": "Fuel Gas Offtake Point",
  "pb_fulcrum_setpoint": "Fulcrum Setpoint",
  "id": "ID",
  "plunger_piston_size": "Plunger Piston Size",
  "process_block": "Process Block",
  "process_block_id": "Process Block ID",
  "process_block_id_unobtainable": "Process Block ID Unobtainable?",
  "notes": "Notes",
  "source_is_operating": "Operating?",
  "component_output_pressure_range": "Output Pressure Range",
  "pump_power_rating": "Pump Power Rating",
  "pump_power_rating_unobtainable": "Pump Power Rating Unobtainable?",
  "pump_type": "Pump Type",
  "release_point_coordinates": "Release Point Coordinates",
  "release_point_latitude": "Release Point Latitude",
  "release_point_longitude": "Release Point Longitude",
  "component_serial_unobtainable": "Serial Number Unobtainable?",
  "site_inspection_record": "Site Inspection Record",
  "site_inspection_record_id": "Site Inspection Record ID",
  "stale": "Stale?",
  "stroke_length": "Stroke Length",
  "strokes_per_minute": "Strokes Per Minute",
  "component_supply_pressure": "Supply Pressure",
  "unique_post_id": "Unique Post ID",
  "unique_well_identifier": "Unique Well Identifier",
  "unique_well_identifier_id": "Unique Well Identifier ID",
  "updated_at": "Updated At",
  "uwi_unobtainable": "UWI Unobtainable?",
  "legacy_venting_or_operating_outside_normal_conditions": "Venting or Operating Outside Normal Conditions",
  "perspective_image": "Perspective Photo",
  "perspective_image_filename": "Perspective Image Filename"
}