export default {
  "inspection_date": "Inspection Date",
  "site_location": "Site Location",
  "compressor_type": "Compressor Type",
  "compressor_unit_number": "Unit Number",
  "rod_packing_emissions_rate_std": "Rod Packing Emissions Rate (STP)",
  "crank_case_emissions_rate_std": "Crank Case Emissions Rate (STP)",
  "centrifugal_seal_emissions_rate_std": "Centrifugal Seal Emissions Rate (STP)",
  "average_rate_per_throw_std": "Average Rate Per Throw (STP)",
  "total_emissions_rate_by_type_std": "Total Emissions Rate By Type (STP)",
  "area": "Area",
  "centrifugal_seal_emission_video_link": "Centrifugal Seal Emission Video",
  "centrifugal_seal_emissions_rate": "Centrifugal Seal Emissions Rate",
  "centrifugal_seal_emissions_rate_not_measurable": "Centrifugal Seal Emissions Rate Not Measurable?",
  "centrifugal_seal_gpe_media": "Centrifugal Seal GPE Media Number",
  "centrifugal_seal_measurement_device": "Centrifugal Seal Measurement Device",
  "centrifugal_seal_measurement_device_id": "Centrifugal Seal Measurement Device ID",
  "centrifugal_seal_measurement_method": "Centrifugal Seal Measurement Method",
  "centrifugal_seal_measurement_method_id": "Centrifugal Seal Measurement Method ID",
  "common_vent": "Common Vent?",
  "compressor_source": "Compressor Source",
  "compressor_source_id": "Compressor Source",
  "compressor_state_when_measured": "Compressor State When Measured",
  "crank_case_emission_video_link": "Crank Case Emission Video",
  "crank_case_emissions_rate": "Crank Case Emissions Rate",
  "crank_case_emissions_rate_not_measurable": "Crank Case Emissions Rate Not Measurable?",
  "crank_case_gpe_media": "Crank Case GPE Media Number",
  "crank_case_inaccessible": "Crank Case Inaccessible?",
  "crank_case_measurement_method": "Crank Case Measurement Method",
  "crank_case_measurement_device": "Crank Case Measurement Device",
  "crank_case_measurement_device_id": "Crank Case Measurement Device ID",
  "crank_case_measurement_method_id": "Crank Case Measurement Method ID",
  "created_at": "Created At",
  "data_source": "Data Source",
  "device_coordinates": "Device Coordinates",
  "discarded_at": "Discarded At",
  "discharge_pressure": "Discharge Pressure",
  "distance_piece_emissions_rate": "Distance Piece Emissions Rate",
  "distance_piece_emissions_rate_std": "Distance Piece Emissions Rate (STP)",
  "distance_piece_gpe_media": "Distance Piece GPE Media Number",
  "distance_piece_measurement_device": "Distance Piece Measurement Device",
  "distance_piece_measurement_device_id": "Distance Piece Measurement Device ID",
  "distance_piece_measurement_method": "Distance Piece Measurement Method",
  "distance_piece_measurement_method_id": "Distance Piece Measurement Method ID",
  "distance_piece_unmeasurable": "Distance Piece Unmeasurable?",
  "emission_control_method": "Emission Control Method",
  "facility": "Facility",
  "id": "ID",
  "notes": "Notes",
  "number_of_throws": "Number Of Throws",
  "region": "Region",
  "release_point_coordinates": "Release Point Coordinates",
  "rod_packing_emission_video_link": "Rod Packing Emission Video",
  "rod_packing_emissions_rate": "Rod Packing Emissions Rate",
  "rod_packing_emissions_rate_not_measurable": "Rod Packing Emissions Rate Not Measurable?",
  "rod_packing_gpe_media": "Rod Packing GPE Media Number",
  "rod_packing_measurement_method": "Rod Packing Measurement Method",
  "rod_packing_measurement_device": "Rod Packing Measurement Device",
  "rod_packing_measurement_device_id": "Rod Packing Measurement Device ID",
  "rod_packing_measurement_method_id": "Rod Packing Measurement Method ID",
  "seal_accessibility": "Seal Accessibility",
  "compressor_serial_number": "Serial Number",
  "site_inspection_record": "Site Inspection Record",
  "site_inspection_record_id": "Site Inspection Record ID",
  "controlled": "Source Controlled?",
  "stale": "Stale?",
  "total_emissions_rate": "Total Emissions Rate",
  "total_emissions_rate_std": "Total Emissions Rate (STP)",
  "unique_post_id": "Unique Post ID",
  "updated_at": "Updated At",
  "url_distance_piece_media": "Url Distance Piece Media"
}