<template>
	<div v-if="value !== '' && value !== null" class="relative-position">
		<q-img
			:src="value"
			:style="`${!fill ? 'max-width: ' + maxWidth + ';' : ''} ${!fill && maxHeight ? 'max-height: ' + maxHeight + ';' : ''} min-height: ${minHeight};`"
			@click.stop="dialogVisible = true"
			class="cursor-pointer"
			:alt="filename"
			position="0 0"
			fit="contain"
		/>
		<div
			v-if="showFilename"
			@click.stop="dialogVisible = true"
			class="absolute-bottom-left text-caption cursor-pointer text-no-wrap q-pl-xs text-white"
			style="background-color: rgba(0, 0, 0, .4) !important; z-index: 9; padding: 4px 10px !important; overflow: hidden;"
		>
			{{ filename }}
		</div>
	</div>
  <display-dialog
    v-model="dialogVisible"
    :title="`${filename}`"
    :buttons="[{ name: 'navigate', label: 'View Image', icon: 'mdi-open-in-new', to: value }]"
		full-width
		full-height
		container-class="bg-grey-7 row items-center justify-center"
  >
		<div class="full-width row justify-center ">
			<q-img
				:src="value"
				style="width: 100%; max-width: 80vw; max-height: 70vh;"
				fit="contain"
			/>
		</div>
	</display-dialog>
</template>

<script setup>

import { ref, computed } from 'vue'
import displayDialog from '../display-dialog.vue';

const props = defineProps({
	value: { required: true },
	maxWidth: { type: String, default: '120px' },
	minHeight: { type: String, default: '50px' },
	maxHeight: { type: String, default: '75px' },
	showFilename: Boolean,
	fill: Boolean,
})

const dialogVisible = ref(false);
const filename = computed(() => props.value ? props.value.slice(props.value.lastIndexOf('/') + 1) : '')

function download() {
	fetch(props.value)
		.then((res) => res.blob())
		.then((blob) => {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = url;
			a.download = filename.value;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
		})
		.catch(() => alert('An error sorry'));
}

</script>
