<template>
	<div v-if="value !== '' && value !== null">
		<div
			:style="`max-width: ${!fill ? maxWidth : '600px'}; ${!fill && maxHeight ? 'max-height: ' + maxHeight + ';' : ''}`"
			class="display-video-preview relative-position"
		>
			<video
				@click.stop="dialogVisible = true"
				preload="metadata"
				class="cursor-pointer full-width position-relative"
				:style="`min-height: ${minHeight}; min-width: 100%;`"
			>
				<source
					:src="`${value}`"
					type="video/mp4"
				>
			</video>
			<div
				@click.stop="dialogVisible = true"
				class="display-video-preview-play absolute-full row items-center justify-center cursor-pointer"
			>
				<q-icon
					name="mdi-play"
					color="blue-grey-7"
					:size="fill ? '140px' : '40px'"
					class="q-mb-xs display-video-preview-icon"
				/>
			</div>
		</div>
		<div
			v-if="showFilename"
			@click.stop="dialogVisible = true"
			class="absolute-bottom-left text-caption cursor-pointer text-no-wrap q-pl-xs text-white"
			style="background-color: rgba(0, 0, 0, .4) !important; z-index: 9; padding: 4px 10px !important; overflow: hidden; bottom: 5px;"
		>
			{{ filename }}
		</div>
	</div>
  <display-dialog
    v-model="dialogVisible"
    :title="`${filename}`"
    :buttons="[{ name: 'navigate', label: 'View Image', icon: 'mdi-open-in-new', to: value }]"
		full-width
		full-height
		container-class="bg-grey-7 row items-center justify-center"
  >
		<div class="full-width row justify-center ">
			<video-player
				:src="value"
				autoplay
				controls
				:width="videoWidth"
				:height="videoHeight"
				:loop="true"
				:volume="0.6"
			/>
		</div>
	</display-dialog>
        

</template>

<script setup>

// https://github.com/surmon-china/videojs-player

import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { VideoPlayer } from '@videojs-player/vue'
import displayDialog from '../display-dialog.vue';
import 'video.js/dist/video-js.css'

const props = defineProps({
	value: { type: String, required: true },
	maxWidth: { type: String, default: '120px' },
	minHeight: { type: String, default: '50px' },
	maxHeight: { type: String, default: '75px' },
	showFilename: Boolean,
	fill: Boolean,
})

const videoWidth = ref(800)
const videoHeight = ref(600)

const dialogVisible = ref(false);
const filename = computed(() => props.value ? props.value.slice(props.value.lastIndexOf('/') + 1) : '')

onMounted(() => {
  videoWidth.value = Math.round(window.innerWidth * 0.7)
	videoHeight.value = Math.round(window.innerHeight * 0.7)
})

</script>

<style>

.display-video-preview {
	width: auto;
	overflow: hidden;
}
.display-video-preview-play>.display-video-preview-icon {
	transition: text-shadow 0.25s ease;
	text-shadow: -2px -2px 4px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.75);
}
.display-video-preview-play:hover>.display-video-preview-icon {
	text-shadow: -4px -4px 6px rgba(255, 255, 255, 0.5), 4px 4px 6px rgba(0, 0, 0, 0.8);
}

</style>