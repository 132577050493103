export default {
  "inspection_date": "Inspection Date",
  "site_location": "Site Location",
  "region": "Region",
  "area": "Area",
  "inspected_by": "Inspected By",
  "inspection_type": "Inspection Type",
  "operator_name": "Operator Name",
  "operator_email": "Operator Email",
  "operator_phone_number": "Operator Phone Number",
  "site_location_type": "Site Location Type",
  "client_account": "Client Account",
  "controlled_tanks_present": "Controlled Tanks Present?",
  "created_at": "Created At",
  "cv_count_of_fugitives_found": "Total Fugitives Found",
  "cv_count_of_fugitives_remaining": "Fugitives Remaining",
  "cv_count_of_fugitives_repaired": "Fugitives Repaired",
  "cv_largest_emission_rate_found": "Largest Fugitive Found",
  "cv_total_fugitive_emission_rate": "Total Fugitive Emission Rate",
  "daily_pressure": "Air Pressure",
  "daily_temp": "Ambient Temperature",
  "data_source": "Data Source",
  "description": "Description",
  "discarded_at": "Discarded At",
  "emitting_pneumatics_present": "Emitting Pneumatics Present?",
  "end_timestamp": "End Timestamp",
  "id": "ID",
  "inspection_notes": "Notes",
  "operator_email_address_unobtainable": "Operator Email Unobtainable?",
  "operator_phone_number_unobtainable": "Operator Phone Number Unobtainable?",
  "precipitation": "Precipitation",
  "scope_of_work": "Scope of Work",
  "shut_in": "Shut In?",
  "site_location_id": "Site Location ID",
  "name": "Site Name",
  "site_placard_image": "Site Placard Photo",
  "site_placard_image_filename": "Site Placard Image Filename",
  "start_timestamp": "Start Timestamp",
  "uncontrolled_tanks_present": "Uncontrolled Tanks Present?",
  "unique_post_id": "Unique Post ID",
  "updated_at": "Updated At",
  "wind_speed": "Wind Speed"
}