<template>

	<span v-if="props.value[0]">{{ value.length === 2 ? value.join(', ') : '' }}
	<div class="google-map">
	<GoogleMap apiKey="AIzaSyD7fKnVVSeROuzb73v_oBUls0YWB3-wR6c" style="width: 100%; height: 500px" :center="center" :zoom="17" :mapTypeId=mapType :mapTypeControl=false :streetViewControl=false>
    <Marker :options="{ position: center }" />
  	</GoogleMap>
	</div>
	</span>

</template>

<script setup>

import { GoogleMap, Marker } from "vue3-google-map";

const props = defineProps(['value'])
const center = {lat: parseFloat(props.value[0]), lng: parseFloat(props.value[1])}
const mapType = "hybrid"

</script>

<style>
table .google-map { display: none;}
</style>
