<template>
  <field-text
    v-model="input"
    :hint="hint"
    :clearable="optional || mode === 'search'"
    @clear="$emit('clear')"
    :error="error"
  >
    <template #prepend>
      <q-icon :name="inputIcon" color="positive" />
    </template>
  </field-text>
</template>

<script setup>

import { ref, computed, watch, inject } from 'vue'
import fieldText from './text.vue'

const props = defineProps({
  modelValue: { type: String, required: false },
  mode: { type: String, default: 'input' },
  hint: { type: String, default: '' },
  optional: Boolean,
  error: { type: String },
})

const emit = defineEmits(['update:modelValue'])
const input = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const inputIcon = computed(() => props.mode === 'search' ? 'mdi-tag-search' : 'mdi-tag')

</script>
