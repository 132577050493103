import fugitive_measurements from './columns/fugitive_measurements'
import compressor_sources from './columns/compressor_sources'
import pneumatic_sources from './columns/pneumatic_sources'
import tank_sources from './columns/tank_sources'
import fired_equipment_sources from './columns/fired_equipment_sources'
import dehydrator_sources from './columns/dehydrator_sources'
import site_inspection_records from './columns/site_inspection_records'
import site_locations from './columns/site_locations'
import compressor_seal_measurements from './columns/compressor_seal_measurements'
import pneumatic_measurements from './columns/pneumatic_measurements'
import tank_measurements from './columns/tank_measurements'
import facilities from './columns/facilities'
import unique_well_identifiers from './columns/unique_well_identifiers'
import fugitive_sources from './columns/fugitive_sources'

export default {
  // keep, used for unit testing and indicating something is missing
  test: 'Example Test String',
  // reusable components
  components: {
    'data-table': {
      'column-actions': 'Actions',
      'filters-and-search': 'Filter',
      'table-columns': 'Columns',
      'table-columns-reset': 'Reset',
      'table-columns-all': 'All',
      'order-sort': 'Sort',
      'table-reset': 'Reset',
      'export-data': 'Export',
      'export-data-filtered': 'Export as CSV',
      'export-data-filtered-spreadsheet': 'Export as CSV for Excel',
      'export-data-unfiltered': 'Export All Data (Ignore Filters)',
    },
    'work-orders': {
      'title': 'Work Orders',
    },
    'work-order': {
      'title': 'Fugitive Emission Work Order',
      // first section
      'site_location': 'Site Location',
      'facility_code': 'Facility ID Code',
      'id': 'Database ID',
      'gpe_tag': 'GPE Field Tag #',
      'date_leak_found': 'Date Leak Found',
      'emission_video_link': 'Video Link',
      // recommendations
      'recommendations': 'Recommendations',
      'repair_recommendation': 'Repair',
      'repair_timeframe_recommendation': 'Repair Timeframe',
      'health_and_safety_issue': 'Health & Safety Issue?',
      'emission_description': 'Emission Description',
      // metrics & conversions
      'cv_emitting': 'Emitting?',
      'value_lost_gas_annually': 'Value of Lost Gas Anually',
      'process_block': 'Process Block',
      'emissions_rate_std': 'Leak Size (STP)',
      'tco2e': 'TCO2E',
      'component_main_type': 'Component Type',
      // form
      'repaired_by': 'Repaired By (Name)',
      'date_of_repair': 'Date of Repair',
      'company_and_title': 'Company & Title',
      'repair_notes': 'Repair Notes',
      'signature': 'Signature',
    },
  },
  // used for display and fields
  types: {
    boolean: {
      positive: 'Yes',
      negative: 'No',
      indeterminate: 'Indetermined',
    },
    date: {
      'days-ago': 'Days Ago',
    },
    'fugitive-status': {
      confirmed: 'Repair Confirmed',
      repaired: 'Repaired',
      delayed: 'Delayed',
      outstanding: 'Outstanding',
      decommissioned: 'Decommissioned',
      historical: 'Historical',
    },
    'site-location': {
      'client-account': 'Client Account',
      'region': 'Region',
      'area': 'Area',
    },
    'measurement-method': {
      '3307235000002877693': 'Soap Solution',
      '3307235000001643113': 'Concentration Measurement',
      '3307235000001643102': 'Metered',
      '3307235000001643085': 'Visual Estimate',
    },
  },
  // page/section specific language
  pages: {
    users: {
      sign_in: {
        'email': 'Email',
        'password': 'Password',
        'remember-me': 'Remember Me',
        'login': 'Log In',
        'forgot': 'Forgot Your Password?',
      },
      reset_password: {
        title: 'Reset Your Password',
        info: 'Provide your email, and we will send you instructions to change your password.',
        send_link: 'Send Me a Reset Link',
        back: 'Back to Log In',
      },
      set_password: {
        title: 'Set a New Password',
        password: 'New Password',
        confirm_password: 'Confirm Password',
        save_password: 'Change My Password',
      },
    },
    inspection: {
      name: 'Inspection Data',
    },
    home: { name: 'Home Dashboard' },
    compressor: { name: 'Compressors' },
    pneumatic: { name: 'Pneumatics' },
    tank: { name: 'Tanks' },
    fugitive_measurements: {
      name: 'Fugitives',
      buttons: {
        'view': 'Details',
        'work-order': 'Work Order',
        'work-orders': 'Work Orders',
      },
      filters: {
        'gpe-tag-search': 'Press ENTER to Search GPE Tags',
      },
    },
    compressor_sources: {
      name: 'Sources',
      buttons: {
        'view': 'Details',
      },
    },
    pneumatic_sources: {
      name: 'Sources',
      buttons: {
        'view': 'Details',
      },
    },
    tank_sources: {
      name: 'Sources',
      buttons: {
        'view': 'Details',
      },
    },
    fired_equipment_sources: {
      name: 'Fired Equipment',
      buttons: {
        'view': 'Details',
      },
    },
    dehydrator_sources: {
      name: 'Dehydrators',
      buttons: {
        'view': 'Details',
      },
    },
    site_inspection_records: {
      name: 'Site Inspection Records',
      buttons: {
        'view': 'Details',
      },
    },
    site_locations: {
      name: 'Site Locations',
      buttons: {
        'view': 'Details',
      },
    },
    compressor_seal_measurements: {
      name: 'Seal Measurements',
      buttons: {
        'view': 'Details',
      },
    },
    pneumatic_measurements: {
      name: 'Measurements',
      buttons: {
        'view': 'Details',
      },
    },
    tank_measurements: {
      name: 'Measurements',
      buttons: {
        'view': 'Details',
      },
    },
    facilities: {},
    unique_well_identifiers: {},
    fugitive_sources: {},
  },
  columns: {
    fugitive_measurements,
    compressor_sources,
    pneumatic_sources,
    tank_sources,
    fired_equipment_sources,
    dehydrator_sources,
    site_inspection_records,
    site_locations,
    compressor_seal_measurements,
    pneumatic_measurements,
    tank_measurements,
    facilities,
    unique_well_identifiers,
    fugitive_sources,
  },
};
