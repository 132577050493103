<template>
  <router-view v-if="userStore.loaded" />
</template>

<script setup>

import { onMounted } from 'vue'
import { Cookies } from 'quasar'
import { useUserStore } from './stores/user'
import { useDataStore } from './stores/data'
import { useRoute, useRouter } from 'vue-router'

const userStore = useUserStore()
const dataStore = useDataStore()
const route = useRoute()
const router = useRouter()

onMounted(async () => {
  await userStore.check()
  if (userStore.authorized) {
    if (route.path === '/users/sign_in') router.push('/inspection/fugitive_measurements')
    dataStore.init()
  } else if (route.path === '/' || route.path === '') {
    Cookies.remove('_portal_session')
  }
})

</script>

<style lang="scss">

body {
  background-color: #F8F9FA;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

.display-unit {
  color: #888;
  letter-spacing: 1px;
  font-size: 80%;
  font-weight: 500;
  margin-left: 4px;
  margin-right: 2px;
}

div.code {
  max-height: 300px;
  overflow-y: auto;
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 11px;
  line-height: 1.5;
  margin: .5em;
  margin-bottom: 1.6em;
  overflow: auto;
  padding: 0.5rem;
  display: block;
  word-wrap: break-word;
  width: 100%;
}
code {
  background: #0000000A;
  border: 1px solid #00000010;
  border-radius: 3px;
  color: #668;
  page-break-inside: avoid;
  font-family: monospace;
  line-height: 1.5;
  padding: 3px 6px;
  display: inline;
}

$container-padding: 100px;

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: $breakpoint-sm-min) {
  .container,
  .container-sm {
    max-width: $breakpoint-xs - $container-padding;
  }
}

@media (min-width: $breakpoint-md-min) {
  .container,
  .container-sm,
  .container-md {
    max-width: $breakpoint-sm - $container-padding;
  }
}

@media (min-width: $breakpoint-lg-min) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: $breakpoint-md - $container-padding;
  }
}

@media (min-width: $breakpoint-xl-min) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: $breakpoint-lg - $container-padding;
  }
}

</style>