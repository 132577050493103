import { createApp } from 'vue'
import { Quasar, Notify, Cookies, LocalStorage, SessionStorage } from 'quasar'
import router from '../router';
import { createPinia } from 'pinia'
import i18n from '../i18n'

import * as display from '../components/display';
import * as fields from '../components/fields';

// import icon/font libraries
import iconSet from 'quasar/icon-set/mdi-v7'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/mdi-v7/mdi-v7.css'
// import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'
// import "@fortawesome/fontawesome-free/js/all";

// import css
import 'quasar/src/css/index.sass'

import App from '../App.vue'

const app = createApp(App)

const pinia = createPinia()
app.use(pinia)

app.use(router)
app.use(i18n)

// configure quasar
app.use(Quasar, {
  iconSet,
  config: {
    brand: {
      /*
      primary: '#1976D2',
      secondary: '#546E7A', // or 6C757D or 9E9E9E
      accent: '#689E32',
      positive: '#82C341',
      negative: '#FF5964',
      info: '',
      warning: '',
      */
      primary: '#1976D2',
      secondary: '#546E7A', // or 6C757D or 9E9E9E
      accent: '#9C27B0',
      positive: '#5EB946', // 21BA44
      negative: '#C10015',
      info: '#31CCEC',
      warning: '#F2C037',
    },
  },
  plugins: [
    Notify,
    Cookies,
    LocalStorage,
    SessionStorage,
  ],
  config: {
    notify: {},
    // loading: {...}, // default set of options for Loading Quasar plugin
    // loadingBar: { ... }, // settings for LoadingBar Quasar plugin
    // ..and many more (check Installation card on each Quasar component/directive/plugin)
  },
})

Object.keys(display).forEach((name) => app.component(name, display[name]))
Object.keys(fields).forEach((name) => app.component(name, fields[name]))

app.mount('#app')
