export default {
  "inspection_date": "Inspection Date",
  "site_location": "Site Location",
  "pneumatic_component_type": "Component Type",
  "component_model": "Make & Model",
  "component_serial": "Serial Number",
  "measurement_method": "Measurement Method",
  "measurement_device": "Measurement Device",
  "emissions_rate": "Emissions Rate",
  "area": "Area",
  "component_image": "Component Photo",
  "component_image_filename": "Component Image Filename",
  "created_at": "Created At",
  "data_source": "Data Source",
  "discarded_at": "Discarded At",
  "pneumatic_driver_type": "Driver Type",
  "emission_video_link": "Emission Video",
  "emissions_rate_not_measurable": "Emissions Rate Not Measurable?",
  "facility": "Facility",
  "gpe_media": "GPE Media Number",
  "id": "ID",
  "measurement_device_id": "Measurement Device ID",
  "measurement_method_id": "Measurement Method ID",
  "source_is_operating": "Operating?",
  "pneumatic_source": "Pneumatic Source",
  "pneumatic_source_id": "Pneumatic Source",
  "region": "Region",
  "site_inspection_record": "Site Inspection Record",
  "site_inspection_record_id": "Site Inspection Record ID",
  "controlled": "Source Controlled?",
  "stale": "Stale?",
  "unique_post_id": "Unique Post ID",
  "updated_at": "Updated At"
}