<template>
	<q-btn
		v-if="value !== '' && value !== null"
		flat
		:label="value"
		dense
		no-caps
		class="text-body1 text-no-wrap"
		:to="`/inspection/facilities/${value}`"
	/>
</template>

<script setup>

import { computed } from 'vue'
import { useDataStore } from '../../stores/data'

const dataStore = useDataStore()

const props = defineProps(['value'])

</script>
