<template>
	<q-btn
		v-if="value !== '' && value !== null"
		label="View"
		icon-right="mdi-open-in-new"
		@click.stop="navigate()"
		dense
		no-wrap
		dark
		size="sm"
		no-caps
		unelevated
		color="grey-3"
		class="text-blue-grey-7 q-pa-xs"
	/>
</template>

<script setup>

const props = defineProps(['value'])

// this exists to disable event propagation
function navigate() {
	window.open(props.value, '_BLANK')
}

</script>
