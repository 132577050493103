<template>
  <q-input
    v-model="input"
		dense
    :outlined="!borderless"
    :borderless="borderless"
    :hint="hint ? t(hint) : ''"
    @clear="$emit('clear')"
    :type="type"
    :label="label"
    :rules="rules"
		:clearable="optional"
    :error="!!error"
    :errorMessage="error"
  >
      <template #prepend>
        <slot name="prepend" />
      </template>
      <template #append>
        <slot name="append" />
      </template>
    </q-input>
  </template>

<script setup>

import { ref, computed, watch, inject } from 'vue'
import buildRules from '../../lib/build-rules'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  modelValue: { type: String, required: false },
  label: String,
  type: { type: String, default: 'text' },
  hint: { type: String, default: '' },
  borderless: { type: Boolean, default: false },
  iconPrepend: Boolean,
  iconAppend: Boolean,
  optional: { type: Boolean, default: false },
  minLenth: { type: Number },
  maxLenth: { type: Number },
  rulesAppend: { type: Array, default: () => [] },
  error: { type: String },
  mode: { type: String },
})

const valueRules = [
  (value) => props.minLenth && value ? (value.length >= props.minLenth || `Value not long enough`) : true,
  (value) => props.maxLenth && value ? (value.length <= props.maxLenth || `Value too long`) : true,
  ...(props.rulesAppend || []),
]
const rules = computed(() => buildRules(valueRules, props.optional || props.mode === 'search'))

const emit = defineEmits(['update:modelValue'])
const input = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

</script>
