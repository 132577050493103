<template>
	<div v-if="value !== '' && value !== null" :class="`display-area ${fill ? 'display-area-fill' : ''}`">
		{{ display }}
	</div>
</template>

<script setup>

import { computed } from 'vue'

const props = defineProps({
	value: String,
	fill: Boolean,
})

const display = computed(()  => props.value ? props.value.replace(/-/g, ' - ').replace(/  -  /g, ' - ') : '')

</script>

<style>

.display-area {
	width: auto;
}

.display-area-fill {
	max-width: none;
	width: 100%;
	white-space: normal;
	overflow-wrap: normal;
}

</style>