<template>
	<span v-if="value !== '' && value !== null" class="text-no-wrap">
		<q-icon name="mdi-map-marker" color="blue-grey-9" />
		{{ value }}
		<q-tooltip :delay="400" :offset="[0, 2]" class="text-body2">
			<div class="q-pa-xs"><b>{{ t('types.site-location.client-account') }}</b> {{ clientAccount.client_name || 'Unknown' }}</div>
			<div class="q-pa-xs"><b>{{ t('types.site-location.region') }}</b> {{ siteLocation.region || 'Not Specified' }}</div>
			<div class="q-pa-xs"><b>{{ t('types.site-location.area') }}</b> {{ siteLocation.area || 'Not Specified' }}</div>
		</q-tooltip>
	</span>
</template>

<script setup>

import { computed } from 'vue';
import { useDataStore } from '../../stores/data';
import { useI18n } from 'vue-i18n'

const dataStore = useDataStore();
const { t } = useI18n()

const props = defineProps(['value'])

const siteLocation = computed(() => dataStore.siteLocations.find(({ code }) => code === props.value))
const clientAccount = computed(() => {
	if (!siteLocation.value || !siteLocation.value.client_account_id) return {}
	return dataStore.clientAccounts.find(({ id }) => id === siteLocation.value.client_account_id)
})

</script>
