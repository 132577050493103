export default {
  "inspection_date": "Inspection Date",
  "site_location": "Site Location",
  "area": "Area",
  "region": "Region",
  "type_of_dehydrator": "Dehydrator Type",
  "flash_gas_control": "Flash Gas Control",
  "still_gas_control": "Still Gas Control",
  "glycol_reboiler_control": "Glycol Reboiler Control",
  "glycol_pump_type": "Glycol Pump Type",
  "bridger_emission_id": "Bridger Emission ID",
  "client_account": "Client Account",
  "created_at": "Created At",
  "data_source": "Data Source",
  "decommissioned": "Decommissioned?",
  "dehy_contactor_pressure": "Contactor Pressure",
  "dehy_contactor_pressure_unobtainable": "Contactor Pressure Unobtainable?",
  "dehy_contactor_temperature": "Contactor Temperature",
  "dehy_contactor_temperature_unobtainable": "Contactor Temperature Unobtainable?",
  "device_coordinates": "Device Coordinates",
  "device_latitude": "Device Latitude",
  "device_longitude": "Device Longitude",
  "discarded_at": "Discarded At",
  "effective_reporting_date": "Effective Reporting Date",
  "facility": "Facility",
  "facility_id": "Facility ID",
  "facility_id_code_unobtainable": "Facility Code Unobtainable?",
  "gas_flow_rate": "Gas Flow Rate",
  "gas_flow_rate_unobtainable": "Gas Flow Rate Unobtainable?",
  "glycol_pump_make_id": "Glycol Pump Make ID",
  "glycol_pump_model": "Glycol Pump Model",
  "glycol_pump_model_id": "Glycol Pump Model ID",
  "glycol_pump_plunger_diameter": "Glycol Pump Plunger Diameter",
  "glycol_pump_plunger_diameter_unobtainable": "Pump Plunger Diameter Unobtainable?",
  "glycol_pump_speed": "Glycol Pump Speed",
  "glycol_pump_speed_unobtainable": "Pump Speed Unobtainable?",
  "glycol_pump_speed_uom": "Glycol Pump Speed Uom",
  "glycol_pump_stroke_length": "Glycol Pump Stroke Length",
  "glycol_pump_stroke_length_unobtainable": "Glycol Pump Stroke Length Unobtainable?",
  "id": "ID",
  "lean_glycol_circulation_rate": "Lean Gylcol Circulation Rate",
  "lean_glycol_circulation_rate_unobtainable": "Lean Gylcol Circulation Rate Unobtainable?",
  "low_temperature_separator_pressure": "Separator Pressure",
  "low_temperature_separator_pressure_unobtainable": "Separator Pressure Unobtainable",
  "low_temperature_separator_temperature": "Separator Temperature",
  "low_temperature_separator_temperature_unobtainable": "Separator Temperature Unobtainable",
  "notes": "Notes",
  "number_of_contactor_trays": "Contactor Tray Count",
  "numberof_contactor_trays_unobtainable": "Contactor Tray Count Unobtainable?",
  "process_block_id": "Process Block ID",
  "process_block_id_unobtainable": "Process Block ID Unobtainable?",
  "reboiler_temperature": "Reboiler Temperature",
  "reboiler_temperature_unobtainable": "Reboiler Temperature Unobtainable?",
  "release_point_coordinates": "Release Point Coordinates",
  "release_point_latitude": "Release Point Latitude",
  "release_point_longitude": "Release Point Longitude",
  "rich_glycol_flash_tank_present": "Flash Tank Present?",
  "rich_glycol_flash_tank_pressure": "Flash Tank Pressure",
  "rich_glycol_flash_tank_pressure_unobtainable": "Flash Tank Pressure Unobtainable?",
  "rich_glycol_flash_tank_temperature": "Flash Tank Temperature",
  "rich_glycol_flash_tank_temperature_unobtainable": "Flash Tank Temperature Unobtainable?",
  "site_inspection_record": "Site Inspection Record",
  "site_inspection_record_id": "Site Inspection Record ID",
  "source_is_operating": "Operating?",
  "stale": "Stale?",
  "stripping_gas_flow_rate": "Stripping Gas Flow Rate",
  "stripping_gas_flow_rate_std": "Stripping Gas Flow Rate (STP)",
  "stripping_gas_flow_rate_unobtainable": "Stripping Gas Flow Rate Unobtainable?",
  "stripping_gas_option": "Stripping Gas Option",
  "unique_post_id": "Unique Post ID",
  "unique_well_identifier": "Unique Well Identifier",
  "unique_well_identifier_id": "Unique Well Identifier ID",
  "updated_at": "Updated At",
  "uwi_unobtainable": "UWI Unobtainable?"
}