export default {
  "inspection_date": "Inspection Date",
  "site_location": "Site Location",
  "area": "Area",
  "region": "Region",
  "unique_tank_identifier": "Unique Tank Identifier",
  "emissions_rate_std": "Emissions Rate (STP)",
  "controlled": "Source Controlled?",
  "blanket_gas": "Blanket Gas Present?",
  "blanket_gas_controlled": "Blanket Gas Controlled?",
  "blanket_gas_emission_control_method": "Blanket Gas Emission Control Method",
  "bridger_emission_id": "Bridger Emission ID",
  "client_account": "Client Account",
  "created_at": "Created At",
  "data_source": "Data Source",
  "decommissioned": "Decommissioned?",
  "device_coordinates": "Device Coordinates",
  "device_latitude": "Device Latitude",
  "device_longitude": "Device Longitude",
  "discarded_at": "Discarded At",
  "effective_reporting_date": "Effective Reporting Date",
  "emission_control_method": "Emission Control Method",
  "facility": "Facility",
  "facility_id": "Facility ID",
  "facility_id_code_unobtainable": "Facility Code Unobtainable?",
  "filename_tank_photo": "Tank Photo",
  "id": "ID",
  "legacy_thief_hatch_open": "Thief Hatch Open?",
  "notes": "Notes",
  "process_block_id": "Process Block ID",
  "process_block_id_unobtainable": "Process Block ID Unobtainable?",
  "release_point_coordinates": "Release Point Coordinates",
  "release_point_latitude": "Release Point Latitude",
  "release_point_longitude": "Release Point Longitude",
  "roof_type": "Roof Type",
  "serial_number": "Serial Number",
  "site_inspection_record": "Site Inspection Record",
  "site_inspection_record_id": "Site Inspection Record ID",
  "source_is_operating": "Operating?",
  "stale": "Stale?",
  "storage_contents": "Contents",
  "storage_hours": "Storage Hours",
  "tank_description": "Description",
  "tank_heated": "Heated?",
  "tank_make": "Make",
  "tank_model": "Model",
  "tank_size": "Tank Size",
  "tank_size_unobtainable": "Tank Size Unobtainable?",
  "unique_post_id": "Unique Post ID",
  "unique_well_identifier": "Unique Well Identifier",
  "unique_well_identifier_id": "Unique Well Identifier ID",
  "updated_at": "Updated At",
  "upstream_pressure": "Upstream Pressure",
  "upstream_pressure_unobtainable": "Upstream Pressure Unobtainable?",
  "upstream_temperature": "Upstream Temperature",
  "upstream_temperature_unobtainable": "Upstream Temperature Unobtainable?",
  "url_tank_photo": "Tank Photo",
  "uwi_unobtainable": "UWI Unobtainable?"
}