export default {
  "fugitive_status": "Fugitive Status",
  "inspection_date": "Inspection Date",
  "site_location": "Site Location",
  "area": "Area",
  "region": "Region",
  "gpe_tag": "GPE Tag Number",
  "emissions_rate_std": "Leak Size (STP)",
  "cv_current_measurement": "Current Measurement?",
  "appendix_13_leak_cause": "Leak Cause - Appendix 13",
  "building_has_leak_detection": "Building has Leak Detection?",
  "camera_distance_from_leak_detection": "Camera Distance from Leak - Detection",
  "camera_distance_from_leak_quantification": "Camera Distance from Leak - Quantification",
  "client_account": "Client Account",
  "component_image": "Component Photo",
  "component_image_filename": "Component Image Filename",
  "component_main_type": "Component Type",
  "created_at": "Created At",
  "date_leak_found": "Date Leak Found",
  "cv_emitting": "Emitting?",
  "cv_saved_rate_cfm": "Saved Rate CFM",
  "cv_tco2_e": "CO2 Equivalent",
  "cv_value_of_lost_gas_annually": "Value of Lost Gas Anually",
  "data_source": "Data Source",
  "discarded_at": "Discarded At",
  "emissions_rate": "Emission Rate (cfm)",
  "legacy_emissions_due_to_failed_pilot_or_ignitor": "Emissions due to Failed Pilot or Ignitor?",
  "emissions_rate_not_measurable": "Emission Rate Not Measurable?",
  "f_days_to_confirm_repair": "Days to Repair Confirmation",
  "f_days_to_repair": "Days to Repair",
  "f_emissions_rate_cfh": "Emission Rate (cfh)",
  "f_emissions_rate_m3h": "Emission Rate (m3/h)",
  "f_saved_rate_cfm": "F Saved Rate Cfm",
  "f_standard_temperature_pressure_rate_m3h": "Emission Rate (STP)",
  "f_tco2e": "F Tco2e",
  "f_valueof_lost_gas_annually": "F Valueof Lost Gas Annually",
  "facility": "Facility",
  "emission_video_link": "Fugitive Emission Video",
  "fugitive_source": "Fugitive Source",
  "fugitive_source_id": "Fugitive Source ID",
  "gpe_media": "GPE Media Number",
  "gpe_media_unobtainable": "GPE Media Unobtainable?",
  "gpe_tag_unobtainable": "GPE Tag Number Unobtainable?",
  "health_safety_issue": "Health/Safety Issue?",
  "id": "ID",
  "leak_located_in_building": "Leak Located in Building?",
  "major_shutdown_requiredto_repair": "Shutdown Required to Repair?",
  "measurement_device": "Measurement Device",
  "measurement_device_id": "Measurement Device ID",
  "measurement_method": "Measurement Method",
  "measurement_method_id": "Measurement Method ID",
  "notes": "Notes",
  "operator_name": "Operator Name",
  "operator_notified_of_health_safety_issue": "Operator Notified of Health/Safety Issue?",
  "operator_repaired_leak_immediately": "Leak Repaired Immediately?",
  "process_block": "Process Block",
  "rationale_for_not_notifying_operator_immediately": "Reason for not Notifying Operator Immediately",
  "repair_confirmation_company": "Repair Confirmation Company",
  "repair_confirmation_date": "Repair Confirmation Date",
  "repair_confirmation_measurement_device": "Repair Confirmation Measurement Device",
  "repair_confirmation_measurement_device_id": "Repair Confirmation Measurement Device ID",
  "repair_confirmation_measurement_method": "Repair Confirmation Measurement Method",
  "repair_confirmation_measurement_method_id": "Repair Confirmation Measurement Method ID",
  "repair_confirmation_media": "Repair Confirmation Media Number",
  "repair_confirmation_ppm_measurement": "Repair Confirmation Measurement",
  "repair_confirmation_video_url": "Repair Confirmation Video",
  "repair_confirmed_by": "Repair Confirmed By",
  "repair_date": "Repair Date",
  "delay_repair_fix_another_day": "Repair Delayed?",
  "repair_notes": "Repair Notes",
  "repair_ppm_measurement_unobtainable": "Repair Confirmation Measurement Unobtainable?",
  "repair_recommendation": "Repair Method Recommendation",
  "repair_timeframe_recommendation": "Repair Timeframe Recommendation",
  "repaired_by": "Repaired By",
  "site_inspection_record": "Site Inspection Record",
  "site_inspection_record_id": "Site Inspection Record ID",
  "site_name": "Site Name",
  "source_description": "Source Description",
  "source_of_off_lease_odor": "Source of Off-Lease Odor?",
  "stale": "Stale?",
  "tco2e": "Tco2e",
  "unique_post_id": "Unique Post ID",
  "updated_at": "Updated At",
  "value_of_lost_gas_annually": "Value Of Lost Gas Annually",
  "work_order": "Work Order"
}