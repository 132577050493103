<template>
	<div v-if="value !== '' && value !== null && value !== undefined" :class="`row items-center no-wrap ${t(`types.fugitive-status.${value}`).includes(' ') ? '' : 'text-no-wrap'}`">
		<q-icon :name="icons[value].icon" :color="icons[value].color" />
		<div class="q-mx-xs" style="line-height: 17px;">{{ t(`types.fugitive-status.${value}`) }}</div>
		<q-icon v-if="editable && value !== 'historical'" name="mdi-pencil" class="text-blue-grey-7 q-ml-xs" />
		<q-tooltip v-if="value === 'historical'">
			This is an historical measurement.
		</q-tooltip>
</div>
</template>

<script setup>

import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps(['value', 'editable', 'historical'])

const icons = {
	confirmed: { icon: 'mdi-checkbox-marked', color: 'positive' },
	repaired: { icon: 'mdi-tools', color: 'primary' },
	delayed: { icon: 'mdi-motion-pause', color: 'warning' },
	outstanding: { icon: 'mdi-close-octagon', color: 'negative' },
	decommissioned: { icon: 'mdi-pipe-valve', color: 'black' },
	historical: { icon: 'mdi-timer-alert-outline', color: 'info' },
}

</script>
