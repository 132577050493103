export default {
  "area": "Area",
  "bridger_emission_id": "Bridger Emission ID",
  "component_main_type": "Component Main Type",
  "component_model": "Component Model",
  "component_size": "Component Size",
  "component_sub_type": "Component Sub Type",
  "created_at": "Created At",
  "decommissioned": "Decomissioned?",
  "device_coordinates": "Device Coordinates",
  "device_latitude": "Device Latitude",
  "device_longitude": "Device Longitude",
  "facility": "Facility",
  "facility_id_code_unobtainable": "Facility ID Code Unobtainable?",
  "id": "ID",
  "notes": "Notes",
  "process_block": "Process Block",
  "process_block_id": "Process Block ID",
  "process_block_id_unobtainable": "Process Block Unobtainable?",
  "region": "Region",
  "release_point_coordinates": "Release Point Coordinates",
  "release_point_latitude": "Release Point Latitude",
  "release_point_longitude": "Release Point Longitude",
  "service_type": "Service Type",
  "service_type_composition": "Service Type Composition",
  "site_inspection_record": "Site Inspection Record",
  "site_location": "Site Location",
  "source_description": "Source Description",
  "sourceis_operating": "Source is Operating?",
  "stale": "Stale?",
  "unique_post_id": "Unique Post ID",
  "unique_well_identifier": "Unique Well Identifier",
  "updated_at": "Updated At",
  "uwi_unobtainable": "UWI Unobtainable?"
}