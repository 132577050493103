<template>
	<q-dialog
		v-model="visible"
	>
		<q-card :class="`column shadow-15 no-wrap`" style="border: 0 !important; max-width: none;">
			<q-bar class="col-auto bg-blue-grey-3" style="height: 36px; border-bottom: 1px solid #789;">
				<div class="text-weight-bold text-blue-grey-9">{{ title }}</div>
	      <q-space />
	      <q-btn round flat icon="mdi-close" class="text-blue-grey-9" v-close-popup />
	    </q-bar>

			<div :class="`col scroll ${containerClass.includes('q-pa') ? '' : 'q-pa-md'} ${containerClass}`" :style="containerStyle">
				<slot />
			</div>

			<q-card-actions class="col-auto bg-blue-grey-3 q-px-md" style="border-top: 1px solid #789;">
				<template v-if="navigation">
					<q-btn label="Previous" icon="mdi-chevron-left" class="bg-white text-black" />
					<q-btn label="Next" icon-right="mdi-chevron-right" class="bg-white text-black" />
				</template>
				<q-space />
				<q-btn
					v-for="(button, i) in buttons"
					:key="`dialog-button-${button.name}-${i}`"
					:disabled="!!button.disabled"
					@click.stop="$emit(button.name, button.data ? button.data : $event)"
					:label="button.label"
					:icon="button.icon"
					class="bg-white text-black"
					target="_BLANK"
					:href="button.to"
					v-close-popup="!!button.autoClose"
				/>
      </q-card-actions>

		</q-card>
	</q-dialog>
</template>

<script setup>

import { ref, computed, watch, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  modelValue: { type: Boolean },
	title: String,
	buttons: Array,
	navigation: Boolean,
	navigationDisablePrevious: Boolean,
	navigationDisableNext: Boolean,
	containerClass: { type: String, default: '' },
	containerStyle: { type: String, default: '' },
})

const emit = defineEmits(['update:modelValue'])
const visible = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

</script>
