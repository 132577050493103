export default {
  "inspection_date": "Inspection Date",
  "site_location": "Site Location",
  "area": "Area",
  "region": "Region",
  "bridger_emission_id": "Bridger Emission ID",
  "client_account": "Client Account",
  "compressor_serial_number": "Compressor Serial Number",
  "compressor_serial_number_unobtainable": "Compressor Serial Number Unobtainable?",
  "created_at": "Created At",
  "data_source": "Data Source",
  "decommissioned": "Decommissioned?",
  "device_coordinates": "Device Coordinates",
  "device_latitude": "Device Latitude",
  "device_longitude": "Device Longitude",
  "discarded_at": "Discarded At",
  "broa_driver_fuel": "Driver Fuel",
  "broa_emission_types": "Emission Type",
  "url_equipment_image": "Equipment Photo",
  "facility": "Facility",
  "facility_id": "Facility ID",
  "facility_id_code_unobtainable": "Facility Code Unobtainable?",
  "filename_equipment_image": "Filename Equipment Image",
  "fired_equipment_make_id": "Fired Equipment Make ID",
  "fired_equipment_model_id": "Fired Equipment Model ID",
  "id": "ID",
  "fired_equipment_model": "Make & Model",
  "notes": "Notes",
  "power_rating": "Power Rating",
  "power_rating_type": "Power Rating Type",
  "power_rating_unobtainable": "Power Rating Unobtainable?",
  "broa_process_block": "Process Block",
  "process_block_id": "Process Block ID",
  "process_block_id_unobtainable": "Process Block ID Unobtainable?",
  "recip_engine": "Reciprocating Engine?",
  "release_point_coordinates": "Release Point Coordinates",
  "release_point_latitude": "Release Point Latitude",
  "release_point_longitude": "Release Point Longitude",
  "serial_number": "Serial Number",
  "serial_number_unobtainable": "Serial Number Unobtainable?",
  "site_inspection_record": "Site Inspection Record",
  "site_inspection_record_id": "Site Inspection Record ID",
  "broa_source_type": "Source Type",
  "source_operating": "Operating?",
  "source_photo_count": "Source Photo Count",
  "stale": "Stale?",
  "unique_post_id": "Unique Post ID",
  "unique_well_identifier": "Unique Well Identifier",
  "unique_well_identifier_id": "Unique Well Identifier ID",
  "updated_at": "Updated At",
  "uwi_unobtainable": "UWI Unobtainable?"
}