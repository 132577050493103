<template>
	<span v-if="value !== '' && value !== null">{{ display }}</span>
</template>

<script setup>

import { computed } from 'vue'

const props = defineProps({
	value: [String, Number],
	sigfigs: { type: [String, Number], default: '' },
})

const display = computed(() => {
	if (props.value === '' || props.value === null) return ''
	const value = ((typeof props.sigfigs === 'number') ? (+props.value).toFixed(props.sigfigs) : `${props.value}`).split('.')
	const numFormat = Intl.NumberFormat('en-US')
	value[0] = numFormat.format(value[0])
	return value.join('.')

})

</script>
